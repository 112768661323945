@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,500;1,200;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton:wght@400&display=swap');

::-webkit-scrollbar {
    display: none;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    color: #333333;
}